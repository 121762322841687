import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {Helmet} from "react-helmet";


export default function Zoo() {
  return (
    <Layout pageTitle="Zoo">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Belfast Zoo - USI Projects</title>
        <meta name="description" content="Our recent work with Belfast Zoo saw us delve into the exploration of new research territories and the design of non-human habitats."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>

      <section class="text-gray-700 body-font heading-container-zoo">
      <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">Belfast Zoo</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                Belfast City Council</p>
              </div>
          </section>
        </div>
      </section>





      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-12 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap">
              <div class="md:w-3/4 py-4 md:px-32 mb-4 md:mb-0">
                <h3 class="text-6xl font-heading text-green-900">OVERVIEW
                </h3>
                <p
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl ">
                  Our recent work with Belfast Zoo saw us delve into the exploration of new research territories 
                  and the design of non-human habitats to create an ambitious vision for the future of this regional asset. 
                  Situated in the heart of the Belfast hills, we reflected on the incredible histories of the region from a 
                  birds eye view to rethink what zoos can do for people, animals, and cities in the 21st Century; from 
                  reclaiming rich socio-cultural heritages to reprogramming disused landscapes, and creating spaces that 
                  positively impact collective wellbeing across Northern Ireland.
                </p> 
              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase">Fields of
                  Practice</p>
                <p
                  class="my-4 text-gray-500 leading-relaxed mt-0">Heritage<br/>Communities<br/>Co-design<br/>Policy & Strategy<br/>Conservation<br/>Wellbeing<br/>Landscaping</p>
                  <p class="text-2xl font-bold uppercase mt-8">Services</p>
                  <p class="my-4 text-gray-500 leading-relaxed mt-0">Citizen Engagement<br/>Concept Development<br/>Illustration<br/>Policy Recomendations<br/>Creative workshops<br/>Thematics</p>
              </div>
            </div>
          </section>
        </div>
      </section>

      <div class="w-full px-5 lg:px-12 py-5">
        <img data-scroll data-scroll-offset="200" data-scroll-speed="2" class="w-full md:pl-20 md:pr-20"
        src="../img/projects/sheila (2).png" alt="Sheila the Elephant"/>
        <p class="text-xl md:text-3xl  md:pl-20 md:pr-20 pt-6 mx-auto w-5/6">
          The project was born out of the need to diversify the Zoo’s current offer and inspire proactive, 
          as opposed to reactive, thinking for its future. In response to this, we’ve created a compost of 
          ideas that can be implemented in the immediate and long-term.
        </p>
      </div>

        <div class="flex flex-wrap md:p-16 md:m-16 px-5">
          <div class="lg:w-3/6 lg:px-5  pb-4 lg::pb-0">
            <p data-scroll data-scroll-speed="2" class="text-xl md:text-3xl ">
             We’re thinking why not rewild the site through new conservation corridors and ‘Floral Hubs’ 
             that pay homage to the famous Floral Hall which historically performed as the heart of hills.<br/><br/>
             This visioning exercise will create capacity for the future identify of Belfast Zoo to unfold and provide that 
             necessary framework for moving its regeneration forward through on the ground co-design, consultation, and intervention.
            </p>
          </div>
          <div class="lg:w-3/6 lg:px-5">
            <img src="../img/projects/zoo-idea-image.png" alt="Zoo Idea"/>            
          </div>
        </div>


      <img data-scroll data-scroll-offset="100" data-scroll-speed="2" class="w-full md:pt-20 md:pb-20 md:pl-20 md:pr-20  py-6"
        src="../img/projects/zoo-drawing.png" alt="Zoo Art"></img>

<div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-green-900">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../relink" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
